import { useState } from "react";
import { Api, CommonEmailValidateApi, CommonEmailValidateApiTimeDelay } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import { useErrorWebhook } from "./useErrorWebhook";


export const useEmailValidation = () => {
  const [emailValidationErr, setEmailValidationErr] = useState(false);
  const { ErrorWebhookTrigger } = useErrorWebhook();
  // const emailValidation = async (email) => {
  //   const getValidation = await Api.get(
  //     "api/v1/get-email-validation?email=" + email,
  //     {}
  //   );
  //   return getValidation;
  // };
  const emailValidation = async (email, uuid, millisconds) => {

    // const getValidation = await CommonEmailValidateApiTimeDelay.get(
    //   "sleep?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&Email='+email,
    //   {}
    let emailVal = 1;
    const getValidation = await CommonEmailValidateApi.post(
      "v1/validate?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&Email=' + email + "&uuid=" + uuid + "&request_time=" + millisconds,
      {}
    ).catch((error) => {
      const erroMessage = {
        serviceName: 'EMAIL VALIDATION',
        errorReason: error.message,
        errorCode: error.code,
        uuid : uuid
      }
      emailVal = 0;
      ErrorWebhookTrigger(erroMessage);
      setEmailValidationErr(true);
      console.log(error);
      if (error.code === 'ECONNABORTED') {
        console.log('email endpoint request timed out');
      } else if (error.getValidation) {
        console.log("email endpoint response error");
      } else if (error.request) {
        console.log("email endpoint request error");
      } else {
        console.log("anything else");
      }

    });
    if (emailVal == 0) {
      let mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!email.match(mailformat)) {
        return {
          data: {
            status_code: 0
          }
        }
      } else {
        return {
          data: {
            status_code: 1
          }
        }
      }
    } else {
      return getValidation;
    }
  };
  return { emailValidation, emailValidationErr };
};
